import { DebugLogger, LocalStorage, LifecycleEmitter, NativeEventBridge, Cacher } from "@ng-mw/shared-react-components"
import { History } from "history"
import { Store } from "redux"
import {
    barcodeFound,
    hideActivateLoyaltyClubPopup,
    hideAppOnboardingPoster,
    hideCheckoutPaymentPopup,
    hideHandoverPickerPopup,
    hideProductPopup,
    hideRegistrationPopup,
    showCheckoutPaymentPopup,
    showHandoverPickerPopup,
    showLoginPopup,
    showPersonalCampaignsPopup,
    showProductPopup,
    showPurchaseComplaintPopup,
    showPurchaseDetailsPopup,
    showRecipeRatingPopup,
    showRegistrationPopup,
    showWelcomePopup,
} from "@app/store/app/appActions"
import { loadBaseData, loadAuthedBaseData } from "@modules/user/store/actions/user"
import { get as getCategories } from "@modules/products/store/actions/categories"
import { createFavoriteRecipes } from "@ng-mw/shared-meny-components/src/modules/recipes/store/actions/favoriteRecipesActions"
import { bonusCampaignActions } from "@ng-mw/shared-meny-components/bonus-campaign"
import { productDetailsActions } from "@ng-mw/shared-react-components/product-details"
import { consentsActions } from "@ng-mw/shared-react-components/consents"
import { userActions } from "@ng-mw/shared-react-components/user"
import MenyLifecycleEvent from "@app/events/MenyLifecycleEvent"
import MenyLocalStorage from "@app/MenyLocalStorage"
import AppGlobalSettings from "@app/AppGlobalSettings"
import { isJwt } from "@app/helpers"
import * as tracking from "@app/tracking"
import { Order, Product, RecipeItem, RootState } from "@app/types"
import scrollIntoView from "scroll-into-view"
import MenyNativeEvent from "@app/events/MenyNativeEvent"
import { setComplaintProduct } from "@modules/orders/store/actions/historyActions"
import { setAppConfigData } from "@app/store/appEnvConfig/appEnvConfigActions"
import PathNames from "@app/const/PathNames"
import Cookie from "@ng-mw/shared-react-components/src/data/Cookie"

export default async (store: Store<RootState, any>, history: History) => {
    // tracking
    LifecycleEmitter.on(MenyLifecycleEvent.Navigation, (data: any) => {
        let userStatus = ""
        const state = store.getState()
        if (AppGlobalSettings.enableTrumfId) {
            userStatus = state.user.loginLevel >= 1 ? "in" : "out"
        } else {
            const token = LocalStorage.get(MenyLocalStorage.UserToken)
            userStatus = token ? "in" : "out"
        }
        const userIsOrganization = state.user.data.household.isOrganization
        let customerType = userIsOrganization ? "corporate" : "private"

        if (userStatus === "out") {
            customerType = ""
        }
        DebugLogger.log("making VirtualPageview: MenyLifecycleEvent.Navigation")
        NativeEventBridge.broadcastNative(MenyNativeEvent.NavigateTo, { data: data.pathname })
        tracking.navigation(data.pathname, userStatus, customerType, data.state)
    })

    // lifecycle
    LifecycleEmitter.on(MenyLifecycleEvent.TrumfProfileUpdated, () => {
        const el = document.getElementById("meny-app-profile-start")
        if (el) {
            scrollIntoView(el, { time: 350 })
        }
    })

    LifecycleEmitter.on(MenyLifecycleEvent.TrumfProfileUpdateCanceled, () => {
        const el = document.getElementById("meny-app-profile-start")
        if (el) {
            scrollIntoView(el, { time: 350 })
        }
    })

    LifecycleEmitter.on(MenyLifecycleEvent.AlternativeAddressUpdated, () => {
        const el = document.getElementById("meny-app-alt-address-start")
        if (el) {
            scrollIntoView(el, { time: 350 })
        }
    })

    LifecycleEmitter.on(MenyLifecycleEvent.AlternativeAddressUpdateCanceled, () => {
        const el = document.getElementById("meny-app-alt-address-start")
        if (el) {
            scrollIntoView(el, { time: 350 })
        }
    })

    interface UserLoggedInData {
        token: string
        isInitalLogin: boolean
    }

    LifecycleEmitter.on(MenyLifecycleEvent.UserLoggedIn, async ({ token, isInitalLogin }: UserLoggedInData) => {
        const state = store.getState()
        const { memberId } = state.user.data.currentMember

        // To avoid double loading of data on startup,
        // ignore login triggered by start up connector setToken
        if (!isInitalLogin) {
            DebugLogger.log("DEBUGG UserLoggedIn: not initial login")
            await store.dispatch(loadBaseData())
            await store.dispatch(loadAuthedBaseData())
        } else {
            DebugLogger.log("DEBUGG UserLoggedIn: INITIAL LOGIN ")
        }

        if (!AppGlobalSettings.enableTrumfId) {
            if (!isJwt(token)) {
                LocalStorage.set(MenyLocalStorage.UserToken, token)
            }
        }

        // Close Trumf ID login drawer if it's open
        store.dispatch(userActions.hideTrumfIdLoginDrawer())

        if (state.user.loading || token !== null) {
            if (state.pushNotifications.data) {
                const status = state.pushNotifications.data.consentActive
                LifecycleEmitter.broadcast(MenyLifecycleEvent.PushConsentUpdated, status)
            } else {
                DebugLogger.log("EV-DEBUG: PushConsentUpdated UserLoggedIn: no data. state.pushNotifications:", state.pushNotifications)
            }
        }

        NativeEventBridge.broadcastNative(MenyNativeEvent.MemberId, memberId)
    })

    LifecycleEmitter.on(MenyLifecycleEvent.UserLogInError, () => {
        if (!AppGlobalSettings.enableTrumfId) {
            LocalStorage.remove(MenyLocalStorage.UserToken)
        }
    })

    LifecycleEmitter.on(MenyLifecycleEvent.PreferredStoreChange, async () => {
        await store.dispatch(loadBaseData())
        store.dispatch(getCategories())
    })

    LifecycleEmitter.on(MenyLifecycleEvent.UpdateCoupon, () => {
        // clear cache to prevent wrong status when returning to coupons page or product views
        Cacher.empty("coupons")
        Cacher.empty("productviews")
    })

    LifecycleEmitter.on(MenyLifecycleEvent.ProductClicked, () => {
        store.dispatch(showProductPopup())
    })

    LifecycleEmitter.on(MenyLifecycleEvent.AgreementStatusChanged, (data: { agreement: any; status: string }) => {
        if (data.status === "true") {
            store.dispatch(hideActivateLoyaltyClubPopup())
            store.dispatch(consentsActions.fetchConsentsAndAgreements([{
                id: AppGlobalSettings.menyMerAgreementId,
            }]))
            store.dispatch(bonusCampaignActions.getRelevantBonuses())
        }
    })

    LifecycleEmitter.on(MenyLifecycleEvent.OrderEditIsEntered, () => {
        if (history.location.pathname !== PathNames.Home) {
            history.replace({
                pathname: PathNames.Cart,
                state: { isImmediate: true },
            })
        }
    })

    LifecycleEmitter.on(MenyLifecycleEvent.OpenHandoverPicker, () => {
        store.dispatch(showHandoverPickerPopup())
    })

    LifecycleEmitter.on(MenyLifecycleEvent.CloseHandoverPicker, () => {
        store.dispatch(hideHandoverPickerPopup())
    })

    LifecycleEmitter.on(MenyLifecycleEvent.OpenCheckout, () => {
        const state = store.getState()
        const userIsLoggedIn = state.user.loginLevel > 0

        const loginData = {
            title: "Logg inn for å fullføre handelen",
            text: "For å bestille varer i appen må du logge inn med Trumf. Ønsker du å fortsette som gjest kan du handle i nettbutikken på meny.no.",
            externalLink: "https://meny.no/varer",
            externalLinkText: "Handle på meny.no",
        }

        if (!userIsLoggedIn) {
            store.dispatch(showLoginPopup(loginData))
        } else if (history.location.pathname !== PathNames.Checkout) {
            history.push(PathNames.Checkout)
        }
    })

    LifecycleEmitter.on(MenyLifecycleEvent.OpenCheckoutPayment, (paymentUrl: string) => {
        store.dispatch(showCheckoutPaymentPopup(paymentUrl))
    })

    LifecycleEmitter.on(MenyLifecycleEvent.CloseCheckoutPayment, () => {
        store.dispatch(hideCheckoutPaymentPopup())
    })

    LifecycleEmitter.on(MenyLifecycleEvent.AddToCartEventOnNumOfItemsInCart, ({ numCartItems }: { numCartItems: number }) => {
        const state = store.getState()
        const userIsLoggedIn = state.user.loginLevel > 0
        const userIsCorporate = state.user.data.household.isOrganization

        const loginPromptCookie = "LOGIN_NUM_CART_ITEMS_PROMPT"
        const hasLoginPromptCookie = Cookie.get(loginPromptCookie)
        const loginData = {
            title: "Bli MENY MER-kunde for å handle i appen",
            text: `Ønsker du å bestille varer i appen må du være MENY MER-kunde. Da får du mer Trumf-bonus på utvalgte produkter, personlige kuponger, og 10 % Trumf-bonus på din første handel.

MENY MER er en del av Trumf, og for å bli MENY MER-kunde må du være Trumf-medlem.`,
        }

        if (numCartItems >= AppGlobalSettings.addToCartEventOnNumOfItemsInCart && !userIsCorporate && !userIsLoggedIn && !hasLoginPromptCookie) {
            store.dispatch(showLoginPopup(loginData))
            Cookie.set(loginPromptCookie, true, 1)
        }
    })

    LifecycleEmitter.on(MenyLifecycleEvent.OpenBecomeMember, () => {
        store.dispatch(showRegistrationPopup())
    })

    LifecycleEmitter.on(MenyLifecycleEvent.ExternalLink, (data: any) => {
        NativeEventBridge.broadcastNative(MenyNativeEvent.ExternalLink, data)
    })

    LifecycleEmitter.on(MenyLifecycleEvent.GoToCouponsClicked, () => {
        // Hide product popup (where the GoToCouponsClicked might be triggered from)
        store.dispatch(hideProductPopup())
    })

    LifecycleEmitter.on(MenyLifecycleEvent.OrderConfirmed, (order: Order) => {
        const uniqueRecipeId = [...new Set(order.cart.filter(item => item.parentRecipeName).map(item => item.parentRecipeId))]

        if (!uniqueRecipeId
            || uniqueRecipeId.length === 0) {
            return null
        }

        store.dispatch(createFavoriteRecipes(uniqueRecipeId, true, false))
    })

    LifecycleEmitter.on(MenyLifecycleEvent.PushConsentUpdated, (status: boolean) => {
        const state = store.getState()

        const oldStatus = LocalStorage.get(MenyLocalStorage.MobilePushStatus)
        if (oldStatus !== status && state.user.loginLevel >= 1) {
            LocalStorage.set(MenyLocalStorage.MobilePushStatus, status)
            DebugLogger.log("EV-DEBUG: PushConsentUpdated to NEW value! DOING NOTHING (old:", oldStatus, " new:", status, ")")
        } else {
            DebugLogger.log("EV-DEBUG: PushConsentUpdated to same value, ignoring. (old:", oldStatus, " new:", status, ")")
        }
    })

    NativeEventBridge.listen(MenyNativeEvent.ScanBarcodeSuccess, (response: any) => {
        const { barcode } = response
        DebugLogger.log("Got barcode response! ", response, " the barcode is ", barcode)

        store.dispatch(productDetailsActions.getProductDetails(barcode))
        store.dispatch(barcodeFound(barcode))

    }, false) // false is very important; it makes it listen more than once

    // New app config format
    NativeEventBridge.listen(MenyNativeEvent.AppEnvConfig, (config: any) => {
        DebugLogger.log("Modern wrapper, got AppEnvConfig", config)
        store.dispatch(setAppConfigData(config))
        tracking.nativeConfigData(config)
    }, false) // false is very important; it makes it listen more than once

    DebugLogger.log("Load-order-debug: Listening for MenyNativeEvent.AppEnvConfig IMPORTANT")
    NativeEventBridge.broadcastNative(MenyNativeEvent.AskForAppEnvConfig)

    setTimeout(() => {
        DebugLogger.log("making VirtualPageview: INITIAL-LOAD-COMPLETE")

        const state = store.getState()
        let userStatus = ""
        if (AppGlobalSettings.enableTrumfId) {
            userStatus = state.user.loginLevel >= 1 ? "in" : "out"
        } else {
            const token = LocalStorage.get(MenyLocalStorage.UserToken)
            userStatus = token ? "in" : "out"
        }
        const userIsOrganization = state.user.data.household.isOrganization
        let customerType = userIsOrganization ? "corporate" : "private"

        if (userStatus === "out") {
            customerType = ""
        }
        tracking.navigation("INITIAL-LOAD-COMPLETE", userStatus, customerType)
    })

    LifecycleEmitter.on(MenyLifecycleEvent.TransactionHistoryDetailsClicked, () => {
        store.dispatch(showPurchaseDetailsPopup())
    })

    LifecycleEmitter.on(MenyLifecycleEvent.TransactionComplaintClicked, (complaintProduct: Product) => {
        store.dispatch(setComplaintProduct(complaintProduct))
        store.dispatch(showPurchaseComplaintPopup())
    })

    LifecycleEmitter.on(MenyLifecycleEvent.TransactionHistoryDetailsClicked, () => {
        store.dispatch(showPurchaseDetailsPopup())
    })

    LifecycleEmitter.on(MenyLifecycleEvent.recipeRatingClicked, (recipeItem: RecipeItem) => {
        store.dispatch(showRecipeRatingPopup(recipeItem))
    })

    LifecycleEmitter.on(MenyLifecycleEvent.OpenPersonalCampaigns, () => {
        store.dispatch(showPersonalCampaignsPopup())
    })

    LifecycleEmitter.on(MenyLifecycleEvent.RegistrationCompleted, (data: any) => {
        store.dispatch(hideAppOnboardingPoster())
        store.dispatch(hideRegistrationPopup())
        store.dispatch(showWelcomePopup(data))
    })

    LifecycleEmitter.on(MenyLifecycleEvent.ActivityStatusChange, (data: number) => {
        DebugLogger.log("ActivityStatusChange data: ", data)
    })
}
